var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "preview-goods"
  }, [_c('van-image', {
    attrs: {
      "src": _vm.goods.image
    },
    on: {
      "click": _vm.handlePreviewImage
    }
  }), _c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.goods.name))]), _c('div', {
    staticClass: "size"
  }, [_vm._v("规格：1 * " + _vm._s(_vm.goods.size))]), _c('div', {
    staticClass: "quantity"
  }, [_vm._v(" 数量： "), _vm.goods.large_quantity > 0 ? _c('span', [_vm._v(_vm._s("".concat(_vm.goods.large_quantity).concat(_vm.goods.large_unit)))]) : _vm._e(), _vm.goods.least_quantity > 0 ? _c('span', [_vm._v(_vm._s("".concat(_vm.goods.least_quantity).concat(_vm.goods.least_unit)))]) : _vm._e()])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }