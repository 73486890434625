var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "app-main-container"
  }, [_c('my-nav-bar', {
    attrs: {
      "title": "上货预览",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _c('van-list', {
    staticClass: "list",
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.getList
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, _vm._l(_vm.list, function (goods, index) {
    return _c('div', {
      key: index,
      staticClass: "preview-goods-container"
    }, [_c('preview-goods', {
      attrs: {
        "goods": goods
      }
    })], 1);
  }), 0)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }