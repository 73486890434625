<template>
  <div class="app-main-container">
    <my-nav-bar
      title="上货预览"
      left-text="返回"
      left-arrow
    />
    <van-empty v-if="showEmpty" description="暂无数据" />
    <van-list v-model="loading" class="list" :finished="finished" @load="getList">
      <div v-for="(goods, index) in list" :key="index" class="preview-goods-container">
        <preview-goods :goods="goods" />
      </div>
    </van-list>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import PreviewGoods from './components/preview-goods'
import { getLoadPreviews } from '@/api/load_preview'
export default {
  components: { myNavBar, PreviewGoods },
  data() {
    return {
      list: [],
      listQuery: {
        page: 0
      },
      loading: false,
      finished: false,
      showEmpty: false
    }
  },
  methods: {
    getList() {
      this.listQuery.page++
      this.beginLoad()
      getLoadPreviews(this.listQuery).then(res => {
        this.endLoad()
        this.list = this.list.concat(res.data.list)
        this.loading = false
        this.finished = res.data.list.length < 10
        this.showEmpty = this.list.length === 0
      }).catch(() => {
        this.loading = false
        this.finished = true
        this.showEmpty = true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .list {
    padding: 10px;
  }
  .preview-goods-container {
    margin-bottom: 10px;
  }
  .preview-goods-container:last-child {
    margin-bottom: 0!important;
  }
</style>
