<template>
  <div class="preview-goods">
    <van-image :src="goods.image" @click="handlePreviewImage" />
    <div class="info">
      <div class="name">{{ goods.name }}</div>
      <div class="size">规格：1 * {{ goods.size }}</div>
      <div class="quantity">
        数量：
        <span v-if="goods.large_quantity > 0">{{ `${goods.large_quantity}${goods.large_unit}` }}</span>
        <span v-if="goods.least_quantity > 0">{{ `${goods.least_quantity}${goods.least_unit}` }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { ImagePreview } from 'vant'
export default {
  props: {
    goods: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  methods: {
    handlePreviewImage() {
      if (!this.goods.image) {
        return
      }

      ImagePreview([this.goods.image])
    }
  }
}
</script>

<style lang="scss" scoped>
  .preview-goods {
    display: flex;
    background-color: #ffffff;
    padding: 10px;
  }
  .info {
    margin-left: 10px;
  }
  .name {
    word-break: break-all;
    font-size: 15px;
  }
  .preview-goods .van-image {
    width: 100px;
    height: 100px;
    flex-shrink: 0;
  }
  .size, .quantity {
    font-size: 13px;
    color: #989898;
    margin-top: 5px;
  }
</style>
